<template>
  <v-container
    id="signup"
    fill-height
    class="d-flex flex-column justify-center"
  >
    <v-scroll-y-transition>
      <v-window 
        v-if="ready"
        v-model="current.index"
        touchless
        class="steps fill-height"
        @change="onStepChange"
      >
        <v-window-item 
          v-for="(step, i) in flow"
          :key="'step-'+i"
          class="step fill-height"
          :value="i"
        >
          <v-sheet
            class="sheet scrolls fill-height d-flex flex-column justify-space-between flex-grow-1 text-center"
          >
            <v-card-text class="scrollable pa-8">
              <img 
                :src="require(`@/assets/step-icon-${setIcon}.svg`)"
                class="step-icon mb-4"
              >
              <h5 v-if="step.index>0" class="overline secondary--text my-2">
                Etapa {{ i }}
              </h5>
              <h4 class="step-title text-h6 my-4">
                {{ step.title }}
              </h4>
              <p class="text text-body-1 mt-6 mb-8" v-html="formatText(step.subtitle)" />
              <component 
                :is="step.component"
                :ref="'step'+i"
                :step="step"
                :hive="hive"
                :device="device"
                :appURL="appURL"
                :refresh="refresh"
                @next="onNext"
              >
                <!-- <template v-slot:quick-access>
                  <v-card 
                    v-if="panels.length>0"
                    outlined
                    color="transparent"
                    class="quick-access-card text-left mx-n4 my-8"
                  >
                    <v-card-text
                      class="mb-n8"
                    >
                      <h2 
                        class="text-h6 primary--text mb-4"
                      >
                        <v-icon 
                          color="primary"
                          class="mr-4"
                        >
                          {{ icons.benefits }}
                        </v-icon>
                        Clube Mobees
                      </h2>
                      <p class="text-body-2">
                        A Mobees, em parceria com outras empresas, oferece benefícios exclusivos para nossos motoristas. Aproveite!
                      </p>
                    </v-card-text>
                    <quick-access 
                      :data="panels"
                      headless
                      center
                      max-width="calc(100vw - 32px)"
                      background="transparent"
                      class="quick-access mb-n4"
                      @toggle-help="toggleHelp"
                    />
                  </v-card>
                </template> -->
              </component>
            </v-card-text>
            <v-card-actions 
              v-show="step.cta.toggle&&!benefits.toggle"
              class="cta pa-4"
            >
              <v-btn 
                large
                block
                color="accent"
                class="btn-cta grey--text text--darken-4"
                @click="action('step'+i)"
              >
                {{ step.cta.text }}
              </v-btn>
            </v-card-actions>
          </v-sheet>
        </v-window-item>
      </v-window>
    </v-scroll-y-transition>

    <!-- <v-bottom-sheet
      v-model="benefits.toggle"
    >
      <v-card
        color="grey darken-4"
        class="text-center py-8"
      >
        <v-card-text
          class="mb-n8"
        >
          <v-icon 
            size="40"
            color="primary"
          >
            {{ icons.benefits }}
          </v-icon>

          <h2 
            class="text-h6 primary--text my-4"
          >
            Clube Mobees
          </h2>
          <p class="text-body-2">
            A Mobees, em parceria com outras empresas, oferece benefícios exclusivos para nossos motoristas. Aproveite!
          </p>
        </v-card-text>
        
        <m-alerts 
          :data="panels"
          headless
          center
          background="transparent"
          @toggle-help="toggleHelp"
        />

        <v-btn
          text
          small
          color="grey"
          @click="benefits.toggle=false;"
        >
          Não, obrigado
        </v-btn>

      </v-card>
    </v-bottom-sheet> -->


    <onboarding />

    <profile 
      ref="profile"
      :toggle="view.profile.toggle"
      @load="onLoadProfile"
      @step-update="onUpdateProfile"
      @action="action"
      @forceUpdate="forceUpdate"
    />

    <help 
      v-if="helpContent!=null"
      :toggle="view.help.toggle"
      :content="helpContent"
      :hive="hive"
    />

    <tos />

    <privacy-policy />

    <update-dialog :toggle="updateApp" />

    <toast />

    <v-overlay 
      :value="!ready"
      color="grey darken-4"
      fixed
      opacity="1"
      z-index="3"
      class="loading-overlay text-center"
    >
      <loading 
        class="loading mb-6" 
      />
      <span class="d-block text-overline grey--text text--darken-1">
        Carregando
      </span>
    </v-overlay>
  </v-container>
</template>

<style>

  #signup {
    padding: 0;
    width: 100%;
  }
  #main {
    background: var(--v-background-base) !important;
  }
  /* @media (min-width: 600px) and (orientation: landscape) {
    #signup {
      width: 360px;
      max-height: 640px;
      margin: 4rem auto;
      border-radius: 1rem;
      border: 1px solid var(--v-secondary-darken2);
    }
  } */

  #signup .sheet.scrolls {
    max-height: calc(100vh - 56px);
  }

  #signup .step .overline {
    font-size: .75rem !important;
  }
  #signup .step .step-icon {
    height: 25vw;
  }
  #signup .step .step-title {
    font-size: 1.5rem !important;
  }
  #signup .step .quick-access-card {
    max-width: initial;
    border-color: var(--v-secondary-darken2) !important;
  }

  .loading-overlay .loading {
    width: 56px;
    height: 56px;
    opacity: 1;
  }
  

</style>

<script>

  // Icons
  import {
    mdiSale
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { steps } from '@/services/signup'
  import { defaults as profileDefaults } from '@/services/profile.js'

  import { sync, get } from 'vuex-pathify'
  import device from 'mobile-device-detect';
  const moment = require('moment');

  export default {
    name: 'Signup',

    props: {
      active: {
        type: Boolean,
        default: true
      },
      installed: {
        type: Boolean,
        default: false
      },
      modal: {
        type: String,
        default: null
      },
      dev: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        benefits: mdiSale
      },
      current: {
        index: 0,
        key: null,
      },
      steps: _.mapValues(steps, step => {
        if (!step.component) step.component = 'step'+step.index+(step.hasOwnProperty('suffix') ? step.suffix : '');
        return step;
      }),
      benefits: {
        toggle: false,
        opened: false,
        headless: true
      },
      view: {
        help: {
          toggle: false,
        },
        profile: {
          toggle: false,
        },
      },
      onboarding: null,
      show: false,
      profileLoaded: false,
      updated: false,
      updatedAt: null,
      device: device,
      updateApp: false,
      refresh: false,
      helpContent: null,
      appURL: process.env.VUE_APP_ROOT
    }),

    computed: {
      // steps: get('signup/steps'),
      // current: sync('signup/current'),
      views: sync('app/views'),
      help: sync('app/views@help'),
      tickets: sync('app/views@help.tickets'),
      cpf: sync('user/cpf'),
      profile: sync('user/profile'),
      status: sync('user/status'),
      auth: sync('user/auth'),
      express: sync('user/express'),
      toast: sync('app/toast'),
      version: sync('app/version'),

      flow () {
        const steps = this.steps;
        const isOwner = this.isOwner;
        const express = this.express;
        return _.orderBy(_.pickBy(steps, step => {
          const scope = _.isString(step.status) ? [step.status] : step.status;
          return step.status==null || (express===step.express||(_.isNil(step.express) && !(isOwner && scope.includes('SIG'))));
        }), ['index', 'suffix'], ['asc', 'asc']);
      },

      step () {
        const flow = this.flow;
        const current = this.current;
        const step = flow[current.index];
        return step;
      },
      setIcon () {
        const flow = this.flow;
        const current = this.current;
        const icon = this.ready && current.index>0 && current.index in flow ? flow[current.index].icon : 'loading';
        return _.isString(icon) ? icon : icon[current.key];
      },

      isOwner () {
        const isOwner = this.profile.vehicle.isOwner;
        return isOwner===null ? null : isOwner==1;
      },  

      stepReady () {
        const current = this.current;
        return current.key=='ENR' ? this.isOwner!==null : this.show;
      },

      ready () {
        const current = this.current;
        return (this.current.key!=null && this.stepReady) && this.updated && this.profileLoaded;
      },

      panels () {
        let panels = [];
        const sections = this.helpContent;
        const status = this.status;
        const benefits = _.has(sections, 'benefits') ? _.clone(sections['benefits']) : null;
        if (!!benefits) {
          const full = _.has(benefits, 'full') && _.indexOf(benefits.full, status)>=0;
          benefits.full = full;
          if (!full) {
            benefits.content = { 
              available: _.map(_.filter(sections['benefits'].cards, benefit => {
                const section = sections[benefit.key];
                const isTarget = (!_.has(section, 'targetStatus')||_.indexOf(section.targetStatus, status)>=0);
                const isValid = (!_.has(section, 'startsAt')||moment().isAfter(section.startsAt))&&(!_.has(section, 'expiresAt')||moment().isBefore(section.expiresAt));
                return isTarget&&isValid;
              }), benefit => {
                const section = sections[benefit.key];
                const activated = !_.isNil(_.find(this.tickets, ticket => {
                  let activated = false;
                  if (ticket.section==benefit.key) {
                    activated = !ticket.closed||_.has(section, 'props')&&_.has(section.props, 'multiple')&&!section.props.multiple;
                  }
                  return activated;
                }));
                return { ...benefit, activated }
              }),
              expired: []
            }
          }

          if (!_.isEmpty(benefits.content.available)) {
            panels.push({
              issue: 'benefits',
              type: 'benefits',
              view: 'help',
              priority: 31,
              title: 'Clube de vantagens',
              text: '',
              color: 'transparent',
              data: benefits
            })
          }
        }
        return panels;
      },

      hive () {
        const profile = this.profile;
        const hive = _.isNil(profile.hive) ? profileDefaults.hive : profile.hive;
        const uri = 'geo:0,0?q=' + _.replace(_.join([hive.address, hive.city], ', '), ' ', '+');
        return {
          ...hive,
          uri
        }
      },
    },

    watch: {
      $route: {
        // immediate: true,
        handler ($route) {
          if (_.has($route.params, 'step')) {
            const key = $route.params.step;
            this.setStep(key);
          }
        }
      },
      active: {
        immediate: true,
        handler (active) {
          this.visibilityChange(active);
        }
      },
      panels (panels) {
        // if (this.panels.length>0&&this.onboarding=='opened') {
        //   if (_.indexOf(['FDB','REA'], this.status)>=0&&!this.benefits.opened&&!_.every(this.panels[0].data.content.available, ['activated', true])) {
        //     this.benefits.toggle = true;
        //     this.benefits.opened = true;
        //   }
        // }
      },
      modal: {
        immediate: true,
        handler (view) {
          if (!!view&&_.has(this.view, view)) {
            // open view
            if (view=='help') {
              const route = this.$route;
              this.view.help.section = route.query.section;
              this.view.help.context = route.name;
            }
            this.view[view].toggle = true;
          }else{
            _.each(this.view, view => {
              if (_.has(view, 'toggle')&&view.toggle) view.toggle = false;
            })
          }
        }
      },
      helpContent: {
        immediate: true,
        deep: true,
        handler (content) {
          if (!!content&&_.has(content.controller, 'maintenance')&&content.controller.maintenance) {
            this.$router.replace({ path: '/maintenance' });
          }
        }
      }
    },

    methods: {
      ...services,

      action (step) {
        // console.log(step);
        this.$refs[step][0].actionCalled();
      },

      // toggleHelp: function (b, context, section, item) {
      //   this.help.toggle = b; 
      //   this.help.context = !_.isNil(context) ? context : null;
      //   this.help.section = !_.isNil(section) ? section : null;
      //   this.help.item = !_.isNil(item) ? item : null;

      //   // if (b) this.trackPage('help', {
      //   //   hash: context,
      //   //   title: this.help.context != null ? this.helpContent[context].title : null
      //   // });
      // },

      onStepChange (step) {
        console.log('step changed -> ', step);
      },

      goToStep (status) {
        const key = status=='DOC' && this.isOwner ? 'SIG' : status=='FDD' ? 'ENR' : status;
        const current = _.has(this.$route.params, 'step') ? this.$route.params.step : null;
        if (current!=key) {
          this.$router.replace('/signup/'+key);
        }else{
          this.setStep(key);
        }
      },

      setStep (key) {
        const flow = this.flow;
        this.current.index = !!key ? _.findIndex(flow, step => {
          const scope = _.isString(step.status) ? [step.status] : step.status;
          return !!scope && scope.includes(key);
        }) : 0;
        this.current.key = key;
      },

      onNext (data) {
        const { status, info=null } = data;
        const step = this.steps[status];
        if (!!info) this.steps[status] = { ...step, ...info };
        this.goToStep(status);
      },

      onUpdateProfile (data) {
        const step = this.flow[this.current.index];
        if (!!data) this.steps[this.current.key] = { ...step, ...data };
      },

      onLoadProfile (profile) {
        this.goToStep(this.status);
        this.profileLoaded = true;
      },

      getStatus (cpf) {
        console.log('checking cpf status...');

        this.$api
          .get('/statuscpfdriver/'+cpf+'/?format=json')
          .then(response => {
            console.log('getStatus => ',response);
            if(response.data.retorno==200){
              // Store CPF status
              const status = response.data.status;
              if (status=='ACT') {
                this.$router.push('/main');
              }else{
                this.goToStep(status);
              }
              this.status = status; 
              this.statusChange = response.data.dt_Status;
              this.express = response.data.express || false;
              this.updatedAt = moment().valueOf();
              if (!this.updated) {
                this.updated = true;
              }else{
                this.refresh = true;
              }

            }
          })
          .catch(error => {
            this.handleError(error);
            if (this.status==null) {
              this.getout();
            }else{
              // setTimeout(($) => {
              //   $.getStatus();
              // }, 7000, this);
            }
          })
          .finally(() => {
          });
      },

      getNotifications () {
        this.views.notifications.loading = true;
        const cpf = this.rawCPF(this.cpf);
        const token = this.auth.token;

        this.$api
          .get('/getdrivernotifications/'+cpf+'/'+token+'/?format=json')
          .then(response => {
            console.log('getNotifications => ', response);
            if(response.data.retorno!=400){
              const notifications = _.mapValues(_.keyBy(response.data.notifications, 'id'), notification => {
                return {
                  ...notification,
                  sent: moment.utc(notification.sent).local().format('YYYY-MM-DD HH:mm:ss')
                }
              });
              this.views.notifications.items = Object.assign({}, notifications);
              this.views.notifications.updatedAt = moment().valueOf();
              this.views.notifications.loading = false;
            }else{
              // this.toggleToast(
              //   true,
              //   'Erro ao carregar notificações',
              //   5000,
              //   false,
              // );
            }
          })
          .catch(error => {
            setTimeout(($) => {
              $.getNotifications();
            }, 7000, this);
            this.handleError(error, false);
          })
          .finally(() => {
          });
      },

      getProfile () {
        if (_.has(this.$refs, 'profile')) {
          this.$refs['profile'].getProfile();
        }else{
          setTimeout($ => {
            $.getProfile();
          }, 500, this);
        }
      },

      forceUpdate () {
        this.updateApp = true;
      },

      onRefresh () {
        this.refresh = false;
      },

      visibilityChange (active) {
        // check status
        if (active&&(this.updatedAt==null||moment().diff(this.updatedAt, 'minutes')>5)) {
          this.getStatus(this.rawCPF(this.cpf), true);
          this.getNotifications();
          if (_.has(this.$refs, 'profile')) this.$refs['profile'].getProfile();
          const step = 'step'+this.current.index;
          if (_.has(this.$refs, step)) this.$refs[step][0].updateView();
        }
      },
      // closeViews () {
      //   for(let view in this.views) {
      //     if (this.views[view].toggle) this.views[view].toggle = false;
      //   }
      // },
    },

    created () {
      
    },

    mounted () {
      // set GA UserType dimension
      this.$gtag.customMap({ 'dimension1': 'UserType' });
      this.$gtag.set({
        'UserType': 'Candidato'
      });

      this.onboarding = localStorage.getItem("onboarding");

      if (this.status=='DEN') {
        this.getout();
      }else{
        this.getStatus(this.rawCPF(this.cpf));
        this.getNotifications();
        this.getProfile();
        setTimeout(vue => vue.show = true, 1000, this);
      }

      // load help content
      this.loadHelpContent(false);

      this.cancelToasts();
    },

    components: {
      Step0: () => import('@/views/signup/Step0'),
      Step1: () => import('@/views/signup/Step1'),
      Step2: () => import('@/views/signup/Step2'),
      Step3A: () => import('@/views/signup/Step3A'),
      Step3B: () => import('@/views/signup/Step3B'),
      Step4: () => import('@/views/signup/Step4'),
      Step5: () => import('@/views/signup/Step5'),
      // mAlerts: () => import('@/components/mAlerts'),
      Onboarding: () => import('@/views/signup/Onboarding'),
      Profile: () => import('@/views/home/Profile'),
      UpdateDialog: () => import('@/components/UpdateDialog'),
      Toast: () => import('@/components/Toast'),
      Help: () => import('@/views/help/Help'),
      Tos: () => import('@/views/help/tos'),
      PrivacyPolicy: () => import('@/views/help/PrivacyPolicy'),
      Loading: () => import('@/components/IconLoading'),
    },
  }
</script>
